<template>
  <span :uk-icon="name" :class="classNames"></span>
</template>

<script>
export default {
  props: {
    name: {},
    classNames: String
  }
};
</script>

<style lang="less" scoped>
.big-icon {
  transform: scale(2.5);
}
span {
  color: #666666;
  cursor: pointer;
  transition: all 0.2s ease;
  &.black {
    color: #000000;
  }
  &.white {
    color: #ffffff;
  }
  &.light-gray {
    color: #999999;
  }
  &.red {
    color: #ff0000 !important;
    &.no-hover {
      &:hover {
        color: #ff0000 !important;
      }
    }
  }
  &.no-hover {
    &:hover {
      color: inherit !important;
    }
  }
  &:hover {
    &.bmh {
      color: #25aae1;
      transition: all 0.2s ease;
    }
    &.workmaster {
      color: #00a154;
      transition: all 0.2s ease;
    }
  }
}
</style>
